//Userdetails.jsx

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack, Typography, Box, Card, Container, SvgIcon, Tooltip, Chip, IconButton } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Toaster } from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';
import { getAllCategory, getAdminBuytransactions, getAdminDomains, searchSellerDomain } from '../../helper/helper';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';
import FaceIcon from '@mui/icons-material/Face';

const Userdetails = (props) => {
    const { userData } = props;
    const { id } = useParams();
    const [domain, setdomain] = useState([]);
    const [loading, setLoading] = useState(true)
    const [formSubmitCount, setFormSubmitCount] = useState(0);
    const [page, setPage] = useState(1);
    const pageSize = 25;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const [query, setquery] = useState("");
    const [domainLoading, setdomainLoading] = useState(false);
    const location = useLocation();
    const [transaction, settransaction] = useState([]);
    const { first_name, last_name, email, userType, status_n } = location.state;

    useEffect(() => {
        async function fetchData() {
            if (userData && userData !== "" && query === "") {
                setLoading(true);
                try {
                    const response = await getAdminDomains({ user_id: id });
                    let domainData = response.data;
    
                    if (location && location.search.includes('sold')) {
                        domainData = domainData.filter(item => item.status === "2");
                    } else {
                        domainData = domainData.filter(item => item.status !== "2");
                    }
    
                    const categoryList = await getAllCategory();
                    if (categoryList && categoryList.data) {
                        const allCategoryList = categoryList.data;
                        const domainDataArray = domainData.map(item => {
                            if (item.category && item.category.length > 0) {
                                const categoryArray = item.category.map(categoryItem => {
                                    const categoryName = allCategoryList.find(element => element._id === categoryItem);
                                    return categoryName ? categoryName.name : "";
                                });
                                item.category = categoryArray.join(", ");
                            } else {
                                item.category = "";
                            }
                            return item;
                        });
    
                        domainData = domainDataArray;
                    }
                    setdomain(domainData);
                } catch (error) {
                    console.error("Error fetching domain data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        fetchData();
    }, [formSubmitCount, userData, query, location, id]); // Add `id` to dependencies if it's used in the effect
    

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", minWidth: 100 },
        { field: 'name', headerName: 'Domain Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", flex: 2, minWidth: 150, renderCell: (params) => (<>{params.row.name}</>) },
        { field: 'top_level_domain', headerName: 'TLD', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.top_level_domain}</>) },
        { field: 'category', headerName: 'category', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", flex: 2, minWidth: 150, cellClassName: 'font-tabular-nums', renderCell: (params) => (<>{params.row.category}</>) },
        { field: 'price', headerName: 'Price', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.price}</>) },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100",
            minWidth: 130,
            renderCell: (params) => (
                <>
                    {params.row.status === '1' ?
                        <div className="p-2 h-full">
                            <div className="relative flex items-center justify-start h-full text-[#17BB09] bg-[#17BB09]/[11%] py-3 px-2 rounded-lg">
                                <span className="w-2 h-2 bg-[#17BB09] rounded-full min-w-2"></span>
                                <span className='ml-2'>Verified</span>
                            </div>
                        </div>
                        :
                        params.row.status === '2' ?
                            <div className="p-2 h-full">
                                <div className="relative flex items-center justify-start h-full text-[#0095EB] bg-[#0095EB]/[11%] py-3 px-2 rounded-lg">
                                    <span className="w-2 h-2 bg-[#0095EB] rounded-full min-w-2"></span>
                                    <span className='ml-2'>Sold</span>
                                </div>
                            </div>
                            :
                            <div className="p-2 h-full w-full">
                                <div className="relative flex items-center justify-start h-full w-full text-[#BB0909] bg-[#BB0909]/[11%] py-3 px-2 rounded-lg">
                                    <span className="w-2 h-2 bg-[#BB0909] rounded-full min-w-2"></span>
                                    <span className='ml-2'>Unverified</span>
                                </div>
                            </div>
                    }
                </>
            )
        },
    ];

    useEffect(() => {
        async function fetchData() {
            if (userData && userData !== "") {
                setLoading(true);
                try {
                    const response = await getAdminBuytransactions({ buyer_id: id });
                    let transactionData = response.data.data;
                    settransaction(transactionData);
                } catch (error) {
                    console.error("Error fetching transaction data:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.log("User data or ID is invalid");
            }
        }
        fetchData();
    }, [userData, id]);
    
    const column = [
        { field: 'id', headerName: 'ID', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", minWidth: 100 },
        { field: 'name', headerName: 'Domain Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", flex: 2, minWidth: 150, renderCell: (params) => (<>{params.row.domain_name}</>) },
        { field: 'card_type', headerName: 'Card Type', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.card_type}</>) },
        { field: 'account_number_last4', headerName: 'Card', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.account_number_last4}</>) },
        { field: 'card_expiry', headerName: 'Card Expiry', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.exp_month} / {params.row.exp_year}</>) },
        { field: 'transaction_time', headerName: 'Transaction Time', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", flex: 2, minWidth: 150, cellClassName: 'font-tabular-nums', renderCell: (params) => (<>{new Date(params.row.transaction_time).toString()}</>) },
        { field: 'amount', headerName: 'Amount', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>${params.row.amount}</>) },
        { field: 'currency', headerName: 'Currency', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.currency}</>) },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100",
            minWidth: 110,
            renderCell: (params) => (
                <>
                    {params.row.status === '1' ?
                        <Tooltip title="Success">
                            <Chip label={'✓'} color={'success'} icon={<FaceIcon fontSize='15px' />} size='small' sx={{ color: "white", fontWeight: '500' }} />
                        </Tooltip>
                        :
                        <Tooltip title="Failed">
                            <Chip label={'x'} color={'error'} icon={<FaceIcon fontSize='15px' />} size='small' sx={{ color: "white", fontWeight: '500' }} />
                        </Tooltip>
                    }
                </>
            )
        }
    ];

    const domainWithUniqueId = domain && domain.length > 0 && domain.map((user, index) => ({
        id: user._id,
        status: user.status === '1' ? "Active" : "Inactive",
        ...user,
    }));

    const domcurrentPageRows = domainWithUniqueId && domainWithUniqueId.length > 0 && domainWithUniqueId.slice(startIndex, endIndex);

    const handleChange = (e) => {
        setquery(e.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value)
    };
    
    const transactionWithUniqueId = transaction && transaction.length > 0 && transaction.map((user, index) => ({
        id: user._id,
        status: user.status === '1' ? "Active" : "Inactive",
        ...user,
    }));

    const currentPageRow = transactionWithUniqueId && transactionWithUniqueId.length > 0 && transactionWithUniqueId.slice(startIndex, endIndex);

    useEffect(() => {
        setdomainLoading(true);

        const intervalId = setTimeout(async () => {
            if (userData && userData?.email) {
                const obj = {};
                if (query !== "") {
                    obj.key = query;

                    obj.email = userData.email;
                    const domainPromise = await searchSellerDomain(obj);
                    let domainData = domainPromise.data.data;
                    if (location && location.search.includes('sold')) {
                        domainData = domainData.filter(item => item.status === "2");
                    } else {
                        domainData = domainData.filter(item => item.status !== "2");
                    }
                    const categoryList = await getAllCategory();
                    if (categoryList && categoryList.data) {
                        const allCategoryList = categoryList.data;
                        const domainDataArray = [];
                        domainData.forEach(item => {
                            if (item.category && item.category.length > 0) {
                                const categoryArray = [];
                                item.category.forEach(categoryItem => {
                                    const categoryName = allCategoryList.filter(element => element._id === categoryItem);
                                    if (categoryName && categoryName.length > 0) {
                                        categoryArray.push(categoryName[0].name);
                                    }
                                });
                                item.category = categoryArray.join(", ");
                            } else {
                                item.category = "";
                            }
                            domainDataArray.push(item);
                        });
                        domainData = [...domainDataArray];
                    }
                    setdomain(domainData);

                    setdomainLoading(false);
                }
            }
        }, 500);

        return () => clearTimeout(intervalId);
    }, [query, location, userData])

    return (
        <Container
            sx={{
                pt: { xs: 4, sm: 5 },
                pb: { xs: 4, sm: 5 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddong: '20px',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Card
                sx={(theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                    },
                })}
            >

                <Typography variant="h3" align='center' gutterBottom>User Details</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', mt: 5, mb: 5 }}>
                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                        <div className='flex flex-col justify-center items-center'>
                            <b>Email</b>
                            {email}
                        </div>
                    </Card>
                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                        <div className='flex flex-col justify-center items-center'>
                            <b>Full Name</b>
                            {first_name} {last_name}
                        </div>
                    </Card>
                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                        <div className='flex flex-col justify-center items-center'>
                            <b>User Type</b>
                            {userType === "0" ? "Admin" : userType === "1" ? "Seller" : "Buyer"}
                        </div>
                    </Card>
                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                        <div className='flex flex-col justify-center items-center'>
                            <b>Status</b>
                            {status_n === '1' ? "Active" : "Inactive"}
                        </div>
                    </Card>
                </Box>

                {userType === '1' ? (
                    <div>
                        <>
                            <MetaTags title="Domain List" description="Manage your domain data." />
                            {loading ?
                                <div className="flex items-center justify-center h-screen w-full">
                                    <Stack direction="row" alignItems="center" gap={2} >
                                        <Spinner />
                                    </Stack>
                                </div>
                                :
                                <div className='w-full h-screen'>
                                    <Toaster position='top-center' reverseOrder={false}></Toaster>
                                    <div className='flex justify-between items-center p-3 text-black border'>
                                        <span>Domain list</span>
                                        <div className='flex items-center'>
                                            <div className="flex items-center mr-2 bg-white rounded-lg">
                                                <div className="text-[#425CD9] w-[40px] flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"> <path d="M19.35 20.9251L13.325 14.9001C12.825 15.3334 12.2417 15.6709 11.575 15.9126C10.9083 16.1543 10.2 16.2751 9.45 16.2751C7.65 16.2751 6.125 15.6501 4.875 14.4001C3.625 13.1501 3 11.6418 3 9.8751C3 8.10843 3.625 6.6001 4.875 5.3501C6.125 4.1001 7.64167 3.4751 9.425 3.4751C11.1917 3.4751 12.6958 4.1001 13.9375 5.3501C15.1792 6.6001 15.8 8.10843 15.8 9.8751C15.8 10.5918 15.6833 11.2834 15.45 11.9501C15.2167 12.6168 14.8667 13.2418 14.4 13.8251L20.475 19.8501C20.625 19.9834 20.7 20.1543 20.7 20.3626C20.7 20.5709 20.6167 20.7584 20.45 20.9251C20.3 21.0751 20.1167 21.1501 19.9 21.1501C19.6833 21.1501 19.5 21.0751 19.35 20.9251ZM9.425 14.7751C10.775 14.7751 11.925 14.2959 12.875 13.3376C13.825 12.3793 14.3 11.2251 14.3 9.8751C14.3 8.5251 13.825 7.37093 12.875 6.4126C11.925 5.45426 10.775 4.9751 9.425 4.9751C8.05833 4.9751 6.89583 5.45426 5.9375 6.4126C4.97917 7.37093 4.5 8.5251 4.5 9.8751C4.5 11.2251 4.97917 12.3793 5.9375 13.3376C6.89583 14.2959 8.05833 14.7751 9.425 14.7751Z" fill="currentColor" /> </svg>
                                                </div>
                                                <input className="bg-transparent text-black py-2 border-none outline-none text-[1.2rem]" placeholder="Search Your Domain" value={query} onChange={handleChange} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <Stack direction="column" gap={4}>
                                        <Stack gap={1}>
                                            <DataGrid
                                                autoHeight
                                                ColumnFilter
                                                slots={{
                                                    loadingOverlay: () => (loading || domainLoading) && <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div>,
                                                    pagination: () => (
                                                        <Stack spacing={2} justifyContent="center" alignItems="center" className='w-full p-3 bg-[#ffffff] text-center'>
                                                            <Pagination
                                                                count={Math.ceil(domainWithUniqueId && domainWithUniqueId.length > 0 && domainWithUniqueId.length / pageSize)}
                                                                page={page}
                                                                onChange={handlePageChange}
                                                                renderItem={(item) => (
                                                                    <PaginationItem
                                                                        component="div"

                                                                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                                        {...item}
                                                                        onClick={() => handlePageChange(null, item.page)}

                                                                    />
                                                                )}

                                                            />
                                                        </Stack>
                                                    ),
                                                    noRowsOverlay: () => <Typography p={5} textAlign="center" >No Data Available</Typography>
                                                }}
                                                sx={{
                                                    '.MuiDataGrid-columnHeaderTitle': {
                                                        fontWeight: 'bold',
                                                    }, color: "#000", backgroundColor: "#ffffff", fontSize: "13px", lineHeight: "18px", cursor: "pointer", fontWeight: '600',
                                                }}
                                                loading={loading}
                                                rows={domcurrentPageRows && domcurrentPageRows.length > 0 ? domcurrentPageRows : []}
                                                columns={columns}
                                                initialState={{
                                                    ...domcurrentPageRows.initialState,
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            id: false,
                                                        }
                                                    },
                                                }}
                                                disableRowSelectionOnClick
                                            />
                                        </Stack>
                                    </Stack>
                                </div>
                            }
                        </>
                    </div>
                ) : userType === '2' ? (
                    <div>
                        <>
                            <MetaTags title="Transaction" description="Manage your transcation data." />
                            {loading ?
                                <div className="flex items-center justify-center h-screen w-full">
                                    <Stack direction="row" alignItems="center" gap={2} >
                                        <Spinner />
                                    </Stack>
                                </div>
                                :
                                <div className='w-full h-screen'>
                                    <Toaster position='top-center' reverseOrder={false}></Toaster>
                                    <div className='flex justify-between items-center p-3 text-black border'>
                                        <span>Transaction list</span>
                                        <div className='flex items-center'>
                                            <div className="flex items-center mr-2 bg-white rounded-lg">
                                                <div className="text-[#425CD9] w-[40px] flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"> <path d="M19.35 20.9251L13.325 14.9001C12.825 15.3334 12.2417 15.6709 11.575 15.9126C10.9083 16.1543 10.2 16.2751 9.45 16.2751C7.65 16.2751 6.125 15.6501 4.875 14.4001C3.625 13.1501 3 11.6418 3 9.8751C3 8.10843 3.625 6.6001 4.875 5.3501C6.125 4.1001 7.64167 3.4751 9.425 3.4751C11.1917 3.4751 12.6958 4.1001 13.9375 5.3501C15.1792 6.6001 15.8 8.10843 15.8 9.8751C15.8 10.5918 15.6833 11.2834 15.45 11.9501C15.2167 12.6168 14.8667 13.2418 14.4 13.8251L20.475 19.8501C20.625 19.9834 20.7 20.1543 20.7 20.3626C20.7 20.5709 20.6167 20.7584 20.45 20.9251C20.3 21.0751 20.1167 21.1501 19.9 21.1501C19.6833 21.1501 19.5 21.0751 19.35 20.9251ZM9.425 14.7751C10.775 14.7751 11.925 14.2959 12.875 13.3376C13.825 12.3793 14.3 11.2251 14.3 9.8751C14.3 8.5251 13.825 7.37093 12.875 6.4126C11.925 5.45426 10.775 4.9751 9.425 4.9751C8.05833 4.9751 6.89583 5.45426 5.9375 6.4126C4.97917 7.37093 4.5 8.5251 4.5 9.8751C4.5 11.2251 4.97917 12.3793 5.9375 13.3376C6.89583 14.2959 8.05833 14.7751 9.425 14.7751Z" fill="currentColor" /> </svg>
                                                </div>
                                                <input className="bg-transparent text-black py-2 border-none outline-none text-[1.2rem]" placeholder="Search Your Domain" value={query} onChange={handleChange} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <Stack direction="column" gap={4}>
                                        <Stack gap={1}>
                                            <DataGrid
                                                autoHeight
                                                ColumnFilter
                                                slots={{
                                                    loadingOverlay: () => loading && <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div>,
                                                    pagination: () => (
                                                        <Stack spacing={2} justifyContent="center" alignItems="center" className='w-full p-3 bg-[#ffffff] text-center'>
                                                            <Pagination
                                                                count={Math.ceil(transactionWithUniqueId && transactionWithUniqueId.length > 0 && transactionWithUniqueId.length / pageSize)}
                                                                page={page}
                                                                onChange={handlePageChange}
                                                                renderItem={(item) => (
                                                                    <PaginationItem
                                                                        component="div"

                                                                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                                        {...item}
                                                                        onClick={() => handlePageChange(null, item.page)}

                                                                    />
                                                                )}

                                                            />
                                                        </Stack>
                                                    ),
                                                    noRowsOverlay: () => <Typography p={5} textAlign="center" >No Data Available</Typography>
                                                }}
                                                sx={{

                                                    '.MuiDataGrid-columnHeaderTitle': {
                                                        fontWeight: 'bold',
                                                    }, color: "#000", backgroundColor: "#ffffff", fontSize: "13px", lineHeight: "18px", cursor: "pointer", fontWeight: '600',
                                                }}
                                                loading={loading}
                                                rows={currentPageRow && currentPageRow.length > 0 ? currentPageRow : []}
                                                columns={column}
                                                initialState={{
                                                    ...currentPageRow.initialState,
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            id: false,
                                                        }
                                                    }
                                                }}
                                                disableRowSelectionOnClick
                                            />
                                        </Stack>
                                    </Stack>
                                </div>
                            }
                        </>
                    </div>
                ) : (
                    <div>
                    </div>
                )}
            </Card>
        </Container>
    );
};

export default Userdetails;
