import { CloseOutlined, DeleteOutline, DoneSharp, InfoOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Chip, Divider, IconButton, MenuItem, Modal, Select, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { deleteUser, getUserData } from '../../helper/helper';
import { adminUserValidate } from '../../helper/validate';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function User() {
    const [users, setUsers] = useState([]);
    // const [subDetails, setSubDetails] = useState();
    const [edit_User, SetEdit_User] = useState();
    const [show, setShow] = useState(false);
    // const [showFilter, setShowFilter] = useState(false)
    const [formSubmitCount, setFormSubmitCount] = useState(0);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const pageSize = 100;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const formRef = useRef(null);
    // const [fromDate, setFromDate] = useState(null);
    // const [toDate, setToDate] = useState(null);
    // const [firstName, setFirstName] = useState('')

    // const [lastName, setLastName] = useState('')
    // const [email, setEmail] = useState('')


    // const handleFilterClose = () => {
    //     setShowFilter(false)
    // }

    const handleClose = () => {
        SetEdit_User(null);
        // setSubDetails(null);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    async function fetchData() {
        setLoading(true)
        try {
            const response = await getUserData();
            const UserDatas = response.data;
            setUsers(UserDatas);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching user data:", error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [formSubmitCount]);

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", width: 100 },
        { field: 'first_name', headerName: 'First Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", width: 100, renderCell: (params) => (<div className="capitalize font-bold  text-black-100">{params.row.first_name}</div>) },
        { field: 'last_name', headerName: 'Last Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", width: 100, renderCell: (params) => (<div className="capitalize font-bold  text-black-100">{params.row.last_name}</div>) },
        { field: 'email', headerName: 'Email', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", flex: 2, minWidth: 200, renderCell: (params) => (<div className="capitalize font-bold  text-black-100">{params.row.email}</div>) },
        { field: 'user_type_n', headerName: 'User Type', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", width: 100 },
        { field: 'status_n', headerName: 'Status', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", width: 100, renderCell: (params) => <Chip label={params.row.status === '1' ? 'Active' : 'InActive'} color={params.row.status === '1' ? 'success' : 'error'} icon={params.row.status === '1' ? (<DoneSharp fontSize='15px' />) : (<CloseOutlined />)} size='small' sx={{ color: "white", fontWeight: '500' }} /> },

        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            headerClassName: "text-[12px]  bg-[#ffffff]  text-black-100",
            renderCell: (params) => (
                <div>
                    <Link to={`/dashboard/userdetails/${params.row.id}`} state={{
                        email: params.row.email,
                        first_name: params.row.first_name,
                        last_name: params.row.last_name,
                        status_n: params.row.status,
                        userType: params.row.user_type,
                    }}>
                        <IconButton onClick={() =>
                            handleEdit(params.row.id)}
                        >
                            <SvgIcon>
                                <InfoOutlined fontSize='small' />
                            </SvgIcon>
                        </IconButton>
                    </Link>
                    <IconButton onClick={() => handleDelete(params.row.id)}>
                        <SvgIcon>
                            <DeleteOutline fontSize='small' />
                        </SvgIcon>
                    </IconButton>
                </div>
            ),
        },

    ];
    const usersWithUniqueId = (users && Array.isArray(users))
        ? users.map((user, index) => ({
            id: user._id,
            user_type_n: user.user_type === '0' ? "Admin" : user.user_type === '1' ? "Seller" : "Buyer",
            status_n: user.status === '1' ? "Active" : "Inactive",
            ...user,
        }))
        : []; // Default empty array if users is not an array


    const currentPageRows = usersWithUniqueId && usersWithUniqueId.length > 0 && usersWithUniqueId.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setPage(value)
    };


    const formik = useFormik({
        initialValues: {
            first_name: edit_User?.first_name || '',
            last_name: edit_User?.last_name || '',
            email: edit_User?.email || '',
            phone: edit_User?.phone || '',
            user_type: edit_User?.user_type || '',
            prime_user: edit_User?.prime_user || '',
            status: edit_User?.status || '',
            id: edit_User?._id || '',
        },
        enableReinitialize: true,
        validate: adminUserValidate,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async values => {
            // let createPromise = adminCreateUser(values);
            // toast.promise(createPromise, {
            //     loading: "creating...",
            //     success: <b>User Created Successfully..!</b>,
            //     error: <b>Could Not Create User</b>
            // });
            // createPromise.then(function () {
            //     console.log('User Created Successfully');
            //     setShow(false);
            //     setFormSubmitCount((prevCount) => prevCount + 1);
            // }).catch(error => {
            //     // console.log('test');
            //     console.log('create user error - ', error);
            //     toast.error(error);
            // });
        }

    });

    // const formik2 = useFormik({
    //     initialValues: {
    //         user_id: subDetails?.user_id || '',
    //         plan_type: subDetails?.plan_type || '',
    //         remaining_api_calls: subDetails?.remaining_api_calls || '',
    //         total_api_calls: subDetails?.total_api_calls || '',
    //         allowed_rl_pm: subDetails?.allowed_rl_pm || '',
    //         allowed_rl_pd: subDetails?.allowed_rl_pd || '',
    //         status: subDetails?.status || '',
    //         id: subDetails?._id || '',
    //     },
    //     enableReinitialize: true,
    //     // validate: adminUserValidate,
    //     validateOnBlur: false,
    //     validateOnChange: false,
    //     onSubmit: async values => {
    //         // let updateSubPromise = updateSubscription(values);
    //         // toast.promise(updateSubPromise, {
    //         //     loading: "updating...",
    //         //     success: <b>User Subscription Details Updated Successfully..!</b>,
    //         //     error: <b>Could Not Update User Subscription Details</b>
    //         // })
    //         // updateSubPromise.then(function () {
    //         //     console.log('User Subscription Details Updated Successfully');
    //         //     setShow(false);
    //         //     setSubDetails(null);
    //         //     setFormSubmitCount((prevCount) => prevCount + 1);
    //         // }).catch(error => {
    //         //     console.log('create user error - ', error);
    //         //     toast.error(error);
    //         // });
    //     }
    // });

    async function handleEdit(id) {
        // console.log(edit_User);
        // console.log(id);
        const userData = users.find(obj => obj._id === id);
        // console.log(userData);
        SetEdit_User(userData);
        handleShow()
    }

    // console.log('subDetails - ',subDetails);
    async function handleDelete(id) {
        // console.log(id);
        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure you want to delete this user?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let deletePromise = deleteUser(id);
                        toast.promise(deletePromise, {

                            loading: "deleting...",
                            success: <b>User Deleted Successfully..!</b>,
                            error: <b>Could Not User</b>
                        });
                        deletePromise.then(function () {
                            setFormSubmitCount((prevCount) => prevCount + 1);
                        }).catch(error => {
                            // console.log(error);
                            toast.error('Plaese try again! ');
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }

            ]
        });

    }

    return (
        <>
            <MetaTags title="Admin User List" description="Manage your user data." />
            <div className='w-full h-screen'>
                <Toaster position='top-center' reverseOrder={false}></Toaster>
                <Stack direction="column" gap={4}  >
                    {/* <h3>User List</h3> */}
                    <Stack gap={1}>
                        {/* <Stack className='flex flex-col lg:flex-row items-center gap-4' justifyContent="space-between">
                        <Stack gap={2} direction="row" className='items-center'>
                            <Button size="small" startIcon={<FilterList />} style={{ width: "103px", paddingBlock: '10px', fontSize: "14px", textTransform: "capitalize" }} onClick={() => setShowFilter(true)} >Filters</Button>
                            <Button variant="outlined" onClick={handleShow}>
                                Create New User
                            </Button>
                        </Stack>
                    </Stack> */}
                        <DataGrid
                            autoHeight
                            ColumnFilter
                            slots={{
                                // toolbar: GridToolbar ,

                                loadingOverlay: () => loading && <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div>,
                                pagination: () => (
                                    <Stack spacing={2} justifyContent="center" alignItems="center" className='w-full p-3 bg-[#ffffff] text-center'>
                                        <Pagination
                                            count={Math.ceil(usersWithUniqueId && usersWithUniqueId.length > 0 && usersWithUniqueId.length / pageSize)}
                                            page={page}
                                            onChange={handlePageChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    component="div"

                                                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                    {...item}
                                                    onClick={() => handlePageChange(null, item.page)}

                                                />
                                            )}

                                        />
                                    </Stack>
                                ),
                                noRowsOverlay: () => <Typography p={5} textAlign="center" >No Data Available</Typography>
                            }}
                            // componentsProps={{
                            //     toolbar: {
                            //         showQuickFilter: false,
                            //         quickFilterProps: { debounceMs: 500 },
                            //     },
                            // }}
                            sx={{

                                '.MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold',
                                }, color: "#1C1C1E", backgroundColor: "#ffffff", fontSize: "13px", lineHeight: "18px", cursor: "pointer"
                            }}
                            loading={loading}
                            rows={currentPageRows && currentPageRows.length > 0 ? currentPageRows : []}
                            columns={columns}
                            disableRowSelectionOnClick
                        />
                    </Stack>
                </Stack>
                {/* ======================== Create New User Modal================= */}

                <Modal
                    open={show}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: 'scroll' }}
                >

                    <Box sx={style} className='lg:w-3/4 sm:w-[75%] w-[90%] text-[10px]'>
                        <form noValidate ref={formRef} onSubmit={formik.handleSubmit} className='py-4' >
                            <Stack direction='column' gap={2}>
                                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                    <Typography className='md:text-2xl text-lg font-bold'>Create New User</Typography>
                                    <Button size='small' type='submit' style={{ width: '80px' }}>Create</Button>

                                </Stack>
                                <Divider />

                            </Stack>
                            <Stack direction='column' gap={2}>
                                <Stack direction='row' alignItems="center" justifyContent='space-between'>
                                </Stack>
                                <Divider />
                            </Stack>

                            <Stack>
                                <Typography>Name</Typography>
                                <div className='w-full'>
                                    <Stack className='flex flex-col lg:flex-row lg:w-[400px] w-[250px]' gap={1}>
                                        <TextField onChange={formik.handleChange} error={!!(formik.touched.first_name && formik.errors.first_name)} helperText={formik.touched.first_name && formik.errors.first_name} size="small" className='lg:w-[50%] w-full self-center bg-[#ffffff]' e type="text" placeholder="Firstname" name="first_name" value={formik.values.first_name} />
                                        <TextField onChange={formik.handleChange} error={!!(formik.touched.last_name && formik.errors.last_name)} helperText={formik.touched.last_name && formik.errors.last_name} size="small" className='lg:w-[50%] w-full self-center bg-[#ffffff]' type="text" placeholder="Lastname" name="last_name" value={formik.values.last_name} required />
                                    </Stack>
                                </div>
                            </Stack>

                            <Stack>
                                <Typography>Email</Typography>
                                <div className='w-full'>
                                    <TextField sx={{ cursor: 'not-allowed' }} onChange={formik.handleChange} size='small' type="email" placeholder="Email" name="email" value={formik.values.email} />
                                </div>
                            </Stack>
                            <Stack>
                                <Typography>User Type</Typography>
                                <div className='w-full'>

                                    <Select
                                        size='small'
                                        value={formik.values.user_type}
                                        name="user_type"
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value='0'>Admin</MenuItem>
                                        <MenuItem value='1'>Seller</MenuItem>
                                        <MenuItem value='2'>Buyer</MenuItem>
                                    </Select>
                                </div>
                            </Stack>

                            <Stack>
                                <Typography>Status</Typography>
                                <div className='w-full'>

                                    <Select onChange={formik.handleChange}
                                        size='small'
                                        name="status"
                                        value={formik.values.status}
                                    // {...formik.getFieldProps('status')}
                                    // value={formik.values.status}  // Set the value to formik.values.duration
                                    // onChange={formik.handleChange}  // Handle changes in the select dropdown
                                    >
                                        <MenuItem value='1'>Active</MenuItem>
                                        <MenuItem value='0'>Inactive</MenuItem>
                                    </Select>
                                </div>
                            </Stack>
                        </form>
                    </Box>
                </Modal>

            </div >
        </>
    )
};

export default User