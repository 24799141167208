import React from "react";
import { Route, Routes } from "react-router-dom";
import { AdminAuth, BuyerAuth, SellerAuth } from "../../middleware/auth";
import PageNotFound from "../PageNotFound";
import Accountpage from "./Account";
import AddDomain from "./AddDomain";
import Admindomain from "./Admindomain";
import AdminDomainDetails from "./AdminDomainDetails";
import Admintransaction from "./Admintransaction";
import BuyerTransaction from "./BuyerTransaction";
import Category from "./Category";
import Domain from "./Domain";
import DomainDetails from "./DomainDetails";
import Index from "./Index";
import Settings from "./Setting";
import User from "./User";
import Userdetails from "./Userdetails";
import Wishlist from "./Wishlist";
import YourDomain from "./YourDomain";

function Main(props) {

    const { userType, userData } = props;

    return (
        <Routes>
            <Route path="/" element={<Index userType={userType} userData={userData} />} />
            <Route path="/user" element={<AdminAuth><User /></AdminAuth>} />
            <Route exact path="/userdetails/:id" element={<AdminAuth><Userdetails userData={userData} /></AdminAuth>} />
            <Route exact path="/settings" element={<AdminAuth><Settings /></AdminAuth>} />
            <Route exact path="/category" element={<AdminAuth><Category /></AdminAuth>} />
            <Route exact path="/account" element={<Accountpage userData={userData} />} />
            <Route exact path="/addDomain" element={<SellerAuth><AddDomain userType={userType} userData={userData} /></SellerAuth>} />
            <Route exact path="/domain" element={userType === "0" ? <AdminAuth><Admindomain userData={userData} /></AdminAuth> : <SellerAuth><Domain userData={userData} /></SellerAuth>} />
            <Route exact path="/domain/:id" element={<SellerAuth><DomainDetails userData={userData} /></SellerAuth>} />
            <Route exact path="/AdminDomainDetails/:id" element={<AdminAuth><AdminDomainDetails userData={userData} /></AdminAuth>} />
            <Route exact path="/yourdomain" element={<BuyerAuth><YourDomain userData={userData} /></BuyerAuth>} />
            <Route exact path="/transaction" element={userType === "0" ? <AdminAuth><Admintransaction userData={userData} /></AdminAuth> : userType === "2" ? <BuyerAuth><BuyerTransaction userData={userData} /></BuyerAuth> : "No data"} />
            {/* <Route exact path="/support" element={<Support userData={userData} />} /> */}
            <Route exact path="/wishlist" element={<BuyerAuth><Wishlist /></BuyerAuth>} />
            <Route exact path="*" element={<PageNotFound />} />
        </Routes>
    )
};

export default Main;    