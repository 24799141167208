import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Box, Card, Container, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCategory, getDomain, updateDomainCategory } from '../../helper/helper';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';

// const originalWarn = console.warn.bind(console.warn);
// console.warn = (msg, ...args) => {
//     if (msg.startsWith('A props object containing a "key" prop is being spread into JSX:')) {
//         return;
//     }
//     originalWarn(msg, ...args);
// };

function DomainDetails() {
    const [loading, setLoading] = useState(true);
    const [saveLoading, setsaveLoading] = useState(false);
    const [domain, setdomain] = useState();
    const [category, setcategory] = useState();
    const [description, setdescription] = useState('');
    const [expiryDate, setexpiryDate] = useState('');
    const [price, setprice] = useState(0);
    const [priceError, setpriceError] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const params = useParams();
    const [domainid, setdomainid] = useState(params?.id);
    const navigation = useNavigate();


    const handleChange = (event, value) => {
        setSelectedCategories(value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (price > 0) {
            setsaveLoading(true);
            setpriceError(false);
            const categoryArray = [];
            if (selectedCategories && selectedCategories.length > 0) {
                selectedCategories.forEach(item => {
                    const selected = category.filter(cate => cate.title === item.title);
                    if (selected && selected.length > 0) {
                        categoryArray.push(selected[0].id);
                    }
                })
            }
            let userPromise = updateDomainCategory({ category: categoryArray, domain_id: domainid, price: price, description: description });
            userPromise.then(() => {
                setsaveLoading(false);
                toast.success('Update Successfully..!');
                navigation('/dashboard/domain');
            }).catch(error => {
                // console.log('Login error:', error);
                toast.error(error);
                setsaveLoading(false);
            });
        } else {
            setpriceError(true);
            toast.error("Price should not be 0");
        }
    };


    useEffect(() => {
        async function fetchFirstData() {
            if (params && params.id) {
                setdomainid(params.id)
            }
        }
        fetchFirstData();
    }, [params])

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getDomain(domainid);
                let domainData = response.data;
                if (domainData?.status !== "1") {
                    navigation('/dashboard/domain')
                }
                // console.log('domaind', domainData, domainid)
                const categoryList = await getAllCategory();
                if (categoryList && categoryList.data) {
                    const allCategoryList = categoryList.data;
                    const categoryArray = [];
                    allCategoryList.forEach(item => {
                        const obj = {};
                        obj.title = item.name;
                        obj.id = item._id;
                        categoryArray.push(obj);
                    })
                    setcategory(categoryArray);
                    if (domainData.category && domainData.category.length > 0) {
                        const categoryArray = [];
                        const selectedCategoryArray = [];
                        domainData.category.forEach(categoryItem => {
                            const categoryName = allCategoryList.filter(element => element._id === categoryItem);
                            if (categoryName && categoryName.length > 0) {
                                categoryArray.push(categoryName[0].name);
                                selectedCategoryArray.push({ title: categoryName[0].name });
                            }
                        });
                        setSelectedCategories(selectedCategoryArray);
                        domainData.category = categoryArray.join(", ");
                    } else {
                        setSelectedCategories([]);
                        domainData.category = "";
                    }
                    if (domainData?.description) {
                        setdescription(domainData?.description);
                    } else {
                        setdescription('');
                    }
                    if (domainData?.price) {
                        setprice(domainData?.price);
                    } else {
                        setprice(0);
                    }
                    if (domainData?.domain_exp_date && domainData?.domain_exp_date !== "") {
                        const expiry = new Date(domainData?.domain_exp_date);
                        setexpiryDate(expiry);
                    } else {
                        setexpiryDate('');
                    }
                }
                setdomain(domainData);
                setLoading(false);
            } catch (error) {
                toast.error(error);
            }
        }
        fetchData();
    }, [domainid])


    return (
        <>
            <MetaTags title="Domain Details" description="Manage specific domain data." />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            {loading ? <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div> :
                <Container
                    sx={{
                        pt: { xs: 4, sm: 5 },
                        pb: { xs: 4, sm: 5 },
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddong: '20px',
                        gap: { xs: 3, sm: 6 },
                    }}
                >

                    <Card
                        sx={(theme) => ({
                            p: 3,
                            height: 'fit-content',
                            width: '100%',
                            background: 'none',
                            '&:hover': {
                                boxShadow:
                                    theme.palette.mode === 'light'
                                        ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                        : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                            },
                        })}
                    >
                        {domain && category.length > 0 &&
                            <>
                                <Typography variant="h3" align='center' gutterBottom>{domain.name} </Typography>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', mt: 5, mb: 5 }}>
                                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                                        <div className='flex flex-col justify-center items-center'>
                                            <b>Domain Name</b>
                                            {domain.domain_name}
                                        </div>
                                    </Card>
                                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                                        <div className='flex flex-col justify-center items-center'>
                                            <b>Top-level domain (TLD)</b>
                                            .{domain.top_level_domain}
                                        </div>
                                    </Card>
                                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                                        <div className='flex flex-col justify-center items-center'>
                                            <b>Domain Expiry</b>
                                            {expiryDate.toLocaleString()}
                                        </div>
                                    </Card>
                                    <Card sx={{ boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)', width: { xs: "100%", sm: "auto" }, minWidth: 200, mb: 2, p: 3 }}>
                                        <div className='flex flex-col justify-center items-center'>
                                            <b>Domain Registrar</b>
                                            {domain.registrar_name}
                                        </div>
                                    </Card>
                                </Box>
                                <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                                    <Typography variant="h5" gutterBottom>Update Your Domain Details</Typography>
                                    <ReactQuill theme="snow" value={description} onChange={setdescription} />
                                    {/* <Textarea aria-label="Description" value={description} onChange={(e) => setdescription(e.target.value)} minRows={3} placeholder="Description" /> */}
                                    {/* <TextareaAutosize
                                        id="outlined-description"
                                        label="Description"
                                        className="'w-[100%] text-sm font-sans font-normal leading-5 px-3 py-2 rounded-lg shadow-md shadow-slate-100 focus:shadow-lg border border-solid border-slate-300 hover:border-purple-500 focus:border-purple-500 bg-white text-slate-900 focus-visible:outline-0 box-border"
                                        // multiline
                                        // rows={4}
                                        // fullWidth
                                        value={description}
                                        onChange={(e) => setdescription(e.target.value)}
                                        sx={{ mt: 2, mb: 2 }}
                                    /> */}
                                    <TextField
                                        label="Price"
                                        rows={4}
                                        type="number"
                                        min={1}
                                        fullWidth
                                        error={priceError}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        required
                                        value={price}
                                        onChange={(e) => setprice(e.target.value)}
                                        sx={{ mt: 2, mb: 2 }}
                                    />
                                    <Autocomplete
                                        multiple
                                        limitTags={5}
                                        id="multiple-limit-tags"
                                        options={category}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Category" placeholder="Category" />
                                        )}
                                        defaultValue={[category[0]]}
                                        value={selectedCategories}
                                        onChange={handleChange}
                                        isOptionEqualToValue={(option, value) => option.title === value.title}
                                        sx={{ width: '100%', marginTop: "20px", marginBottom: "20px" }}
                                    />
                                    <LoadingButton loading={saveLoading} type="submit" variant="contained" fullWidth>
                                        Save Changes
                                    </LoadingButton>
                                </Box>
                            </>}
                    </Card>
                </Container>
            }
        </>
    );
}

export default DomainDetails;