import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Card, Container, TextField, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import React, { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createDomain } from "../../helper/helper";
import MetaTags from '../MetaTags';

function AddDomain(props) {

    const { userData } = props;
    let navigate = useNavigate();
    const [domain, setDomain] = useState('');
    const [isValidDomain, setIsValidDomain] = useState(false);
    const [error, setError] = useState('');
    const [showReviewSection, setShowReviewSection] = useState(false);
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const goToPreviousPath = () => {
        navigate(-1)
    }

    const handleChange = (event) => {
        setDomain(event.target.value);
    };

    const handleSubmit = useCallback(() => {
        const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
        if (domainRegex.test(domain)) {
            setIsValidDomain(true);
            setError('');
        } else {
            setIsValidDomain(false);
            setError('Please enter a valid domain name.');
        }
    }, [domain]);

    useEffect(() => {
        if (domain && domain !== "") {
            const timeout = setTimeout(handleSubmit(), 500);
            return () => clearTimeout(timeout);
        } else {
            setError('');
        }
    }, [domain, handleSubmit]);

    async function domainFetch() {
        if (isValidDomain) {
            setloadingSubmit(true);
            // descriptionsSet(domain);
            let domainCreate = createDomain({ name: domain, user_id: userData._id });
            domainCreate.then(() => {
                setIsValidDomain(false);
                setShowReviewSection(true);
                setloadingSubmit(false);
                toast.success('Domain Added Successfully..!');
            }).catch(error => {
                setloadingSubmit(false);
                // console.log('Login error:', error);
                toast.error(error);
            });
        }
    }

    return (
        <>
            <MetaTags title="Add Domain" description="Add domain's for sell." />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <Container
                sx={{
                    pt: { xs: 4, sm: 5 },
                    pb: { xs: 4, sm: 5 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: { xs: "100%", sm: 750 },
                    gap: { xs: 3, sm: 6 },
                }}
            >
                {!showReviewSection ?
                    <>
                        <Box sx={{
                            width: '100%',
                            textAlign: "center",
                            color: "#000",
                        }}>
                            <Typography variant="h3" gutterBottom>
                                Begin Importing Your Domains
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                To add names to the marketplace, you need to select the most suitable way to upload your domains
                            </Typography>
                        </Box>
                        <Card
                            sx={(theme) => ({
                                p: 3,
                                height: 'fit-content',
                                width: '100%',
                                background: 'none',
                                boxShadow:
                                    theme.palette.mode === 'light'
                                        ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                        : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                            })}
                        >
                            <Typography variant="h6" gutterBottom> Enter domain name manually </Typography>

                            <TextField
                                label="Domain"
                                variant="outlined"
                                value={domain}
                                onChange={handleChange}
                                fullWidth
                                error={!isValidDomain && error !== ''}
                                helperText={!isValidDomain ? error : ''}
                            />
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={domainFetch}
                                disabled={!isValidDomain}
                                loading={loadingSubmit}
                                sx={{ mt: 2 }}
                            >
                                Submit
                            </LoadingButton>
                        </Card>
                    </> :
                    <>
                        <Box sx={{ width: '100%', textAlign: "center", color: "#000", }}>
                            <Typography variant="h3" gutterBottom>
                                Review Domains
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                To add names to the marketplace, you need to select the most suitable way to upload your domains
                            </Typography>
                        </Box>

                        <Card
                            sx={(theme) => ({
                                p: 3,
                                height: 'fit-content',
                                width: '100%',
                                background: 'none',
                                boxShadow:
                                    theme.palette.mode === 'light'
                                        ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                        : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                            })}
                        >
                            <Typography variant="h5" sx={{ fontWeight: "600" }} gutterBottom> Enter domain name </Typography>
                            <div className="flex mb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                <CheckCircleIcon sx={{ color: "green", marginRight: "10px" }} />
                                {domain} domain Added Successfully..!
                            </div>
                            <Typography variant="h5" sx={{ fontWeight: "600" }} gutterBottom> Here's the next steps </Typography>
                            <Typography variant="h6" gutterBottom> Point your A record servers to verify ownership </Typography>
                            <Typography variant="body2" gutterBottom> 170.64.225.16 <ContentCopyIcon sx={{ cursor: "pointer" }} fontSize='small' onClick={() => { copy('170.64.225.16'); toast.success("Copied to clipboard!") }} /> </Typography>
                            <Button variant="contained" color="primary" onClick={goToPreviousPath} sx={{ mt: 2 }}> Submit </Button>
                        </Card>
                    </>
                }
            </Container>
        </>
    )
};

export default AddDomain;